:root {
  --bg: #e6ffff;
  --bd: #6cc2c2;
  --fs: 16px;
}

* {
  box-sizing: border-box;
}

html, body {
  font-size: var(--fs);
  margin: 0;
  padding: 0;
}

html {
  background-color: #e4e4e4;
}

body {
  background-color: #fff;
  max-width: 850px;
  margin: 2em auto;
  padding: 3em 1.5em;
  box-shadow: 0 3px 10px #0006;
}

h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 1em;
}

fieldset, input, label, select, button {
  width: 100%;
  min-width: 0;
  font-size: inherit;
  font-family: inherit;
}

form {
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

fieldset {
  background-color: var(--bg);
  border: 1px solid var(--bd);
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  height: 3em;
  padding: 0;
  display: flex;
  overflow: hidden;
}

fieldset.full > input, select {
  flex: auto;
}

input, select {
  background-color: #fff;
  border: none;
  height: 100%;
  padding-left: 1em;
}

label {
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  flex: none;
  align-items: center;
  width: auto;
  height: 100%;
  padding: 0 1em;
  display: flex;
}

button {
  --a: #000;
  --b: #fabc2a;
  background-color: var(--b);
  border: 1px solid var(--a);
  color: var(--a);
  cursor: pointer;
  border-radius: 6px;
  width: auto;
  height: 3em;
  margin: 3em auto;
  padding: 1em 2em;
}

details {
  margin-bottom: 3em;
}

summary {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 1em;
}

details .items {
  max-height: 300px;
  overflow-y: auto;
}

details .item {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-bottom: 1px solid #0003;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: .5em;
  padding: .5em 1em;
  display: flex;
}

details .item .data {
  white-space: nowrap;
}

details .item .nome {
  font-weight: bold;
}

.full {
  grid-column: span 2;
}

.flex {
  display: flex;
}

.hide {
  display: none;
}

#result {
  height: auto;
  min-height: 90vh;
  position: relative;

  &:after {
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: inset 0 0 10px #0006;
  }
}

iframe {
  border: none;
  width: 100%;
  height: auto;
  min-height: 100vh;
}

#baixar {
  justify-content: center;
  align-items: center;
  gap: 4em;
  margin-top: 3em;
  display: flex;
}

#baixar button {
  margin: 0;
}

@media screen and (width <= 550px) {
  form {
    grid-template-columns: 1fr;
  }

  fieldset {
    flex-direction: column;
    height: 5em;
    padding-left: 0;
  }

  fieldset input, select {
    height: 3em;
  }

  fieldset label {
    height: 2em;
    padding-left: .5em;
    line-height: 2em;
  }

  .full {
    grid-column: span 1;
  }

  #result {
    width: calc(100% + 3em);
    margin-left: -1.5em;
  }

  #baixar {
    flex-direction: column-reverse;
    gap: 2em;
  }

  details .item {
    flex-direction: column;
    margin-bottom: 1.5em;
  }

  details .item .data {
    text-align: right;
    width: 100%;
    font-size: .85em;
  }
}
/*# sourceMappingURL=index.32db55a5.css.map */
