:root {
  --bg: #e6ffff;
  --bd: #6cc2c2;
  --fs: 16px;
}

* { box-sizing: border-box }
html,body {
  margin: 0;
  padding: 0;
  font-size: var(--fs);
}

html { background-color: #e4e4e4; }
body {
  max-width: 850px;
  background-color: white;
  padding: 3em 1.5em;
  margin: 2em auto;
  box-shadow: 0 3px 10px 0px rgba(0,0,0,0.4);
}

h1 {
  text-align: center;
  margin-bottom: 1em;
  margin-top: 0px;
}

fieldset,input,label,select,button {
  width: 100%;
  min-width: 0px;
  font-size: inherit;
  font-family: inherit;
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
}
fieldset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  height: 3em;
  background-color: var(--bg);
  border: 1px solid var(--bd);
  padding: 0px 0px 0px;
  overflow: hidden;
}
fieldset.full > input,select { flex: 1 1 auto; }

input,select {
  border: none;
  background-color: white;
  height: 100%;
  padding-left: 1em;
}
label {
  flex: none;
  width: auto;
  white-space: nowrap;
  padding: 0px 1em;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;
}

button {
  --a: #000;
  --b: #FABC2A;
  background-color: var(--b);
  border: 1px solid var(--a);
  color: var(--a);
  height: 3em;
  width: auto;
  margin: 3em auto;
  padding: 1em 2em;
  border-radius: 6px;
  cursor: pointer;
}

details {
  margin-bottom: 3em;
}
summary {
  cursor: pointer;
  user-select: none;
  margin-bottom: 1em;
}
details .items {
  overflow-y: auto;
  max-height: 300px;
}
details .item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  padding: 0.5em 1em;
  border-bottom: 1px solid rgba(0,0,0, 0.2);
  margin-bottom: 0.5em;
}
details .item .data {
  white-space: nowrap;
}
details .item .nome {
  font-weight: bold;
}

.full { grid-column: span 2; }
.flex { display: flex; }
.hide { display: none; }


/* ------ */

#result {
  height: auto;
  min-height: 90vh;
  position: relative;
  &::after {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.4);
    pointer-events: none;
  }
}
iframe {
  width: 100%;
  height: auto;
  min-height: 100vh;
  border: none;
}
#baixar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4em;
  margin-top: 3em;
}
#baixar button {
  margin: 0px;
}

/* ------- */

@media screen and (max-width: 550px) {
  form {
    grid-template-columns: 1fr;
  }
  fieldset {
    flex-direction: column;
    padding-left: 0px;
    height: 5em;
  }
  fieldset input,select {
    height: 3em;
  }
  fieldset label {
    height: 2em;
    line-height: 2em;
    padding-left: 0.5em;
  }
  .full {
    grid-column: span 1;
  }
  #result {
    width: calc(100% + 3em);
    margin-left: -1.5em;
  }
  #baixar {
    flex-direction: column-reverse;
    gap: 2em;
  }
  details .item {
    flex-direction: column;
    margin-bottom: 1.5em;
  }
  details .item .data {
    width: 100%;
    text-align: right;
    font-size: 0.85em;
  }
}
